.navbar {
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: black; 
    color: white;
}

.navbar-active {
    background-color: rgb(0, 0, 0);
    color: white;
}   

.nav-line {
    background: linear-gradient(to right, rgb(6, 34, 156) 50%, rgb(21, 198, 221));
    height: 16px;
}

.link-text:hover {
    text-decoration: none !important;
    color: rgb(21, 198, 221); 
}